<template>
    <div>
      <DoneAlert :msg="$t('Thank you I will contact you as soon as possible')" :visible="ShowDoneAlert" />
      <ErrorAlert :msg="errorMgs" :visible="ShowError" />

 <b-container  class="p-0" >
     
     
    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 

        <img :src="$store.state.backendUrl+post.image" alt="">
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
       <div v-html="post.description" class="text-right"></div>

      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" class="text-right"> 
         <p> <b-icon icon="envelope" scale="1.25" shift-v="1.25"  aria-hidden="true"></b-icon> {{$t('Email')}} : {{post.email}}</p>
          <p> <b-icon icon="phone" scale="1.25" shift-v="1.25"  aria-hidden="true"></b-icon> {{$t('Phone')}} : {{post.phone1}} <span v-if="post.phone2">/ {{post.phone2}}</span></p>
       <p> <b-icon icon="map" scale="1.25" shift-v="1.25"  aria-hidden="true"></b-icon> {{$t('Address')}} : {{post.address}}</p>

      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
               
             <b-nav class="shareBTNS pr-0" >
                 <b-nav-item> شارك : </b-nav-item>
            <b-nav-item  :href="'https://www.facebook.com/sharer/sharer.php?u='+$store.state.baseUrl+'ar/about'" target="_blank"><b-icon icon="facebook" scale="1" shift-v="1"  aria-hidden="true"></b-icon></b-nav-item>
            <b-nav-item  :href="'https://twitter.com/intent/tweet?text='+post.name+' '+$store.state.baseUrl+'ar/about'" target="_blank"><b-icon icon="twitter" scale="1" shift-v="1" aria-hidden="true"></b-icon></b-nav-item>
     
            </b-nav>
      </b-col>
    </b-row>





<br>   
<b-form @submit="onSubmit" enctype="multipart/form-data">
<b-row>
    <b-col cols="12"  > 
    <div>
    <b-alert :show="ShowError" dismissible variant="danger">
      
    {{errorMgs}}

    </b-alert>
     </div>
     </b-col>
</b-row>

<b-row align-h="center">

    
<b-col cols="12" lg="6" xl="6" > 

    
         <!-- input -->
      <b-form-group class="text-right" :label="$t('Name')" label-for="input-1" :class="{ 'form-group--error': $v.form.name.$error }">
        <b-form-input  v-model.trim="form.name" @input="setTouched('name')" type="text" required ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.name.$error"  :state="$v.form.name.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
  
        <!-- input -->
      <b-form-group class="text-right"  :label="$t('Email')" label-for="input-1" :class="{ 'form-group--error': $v.form.email.$error }">
        <b-form-input   v-model.trim="form.email" @input="setTouched('email')" type="email" required ></b-form-input>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.required"> {{$t('required_form')}} </b-form-invalid-feedback>
         <b-form-invalid-feedback v-if="$v.form.email.$error" :state="$v.form.email.email"> {{$t('email_form')}} </b-form-invalid-feedback>
         <div v-if="errorsBack.email" >
            <b-form-invalid-feedback v-for="error in errorsBack.email" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
       <!-- input -->
      <b-form-group class="text-right"   :label="$t('Phone')" label-for="input-1" :class="{ 'form-group--error': $v.form.phone.$error }">
        <b-form-input  v-model.trim="form.phone" @input="setTouched('phone')" type="text"  ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.phone.$error"  :state="$v.form.phone.numeric"> {{$t('phone_form')}} </b-form-invalid-feedback>
           <div v-if="errorsBack.phone" >
            <b-form-invalid-feedback v-for="error in errorsBack.phone" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
     
  
      <!-- input -->
      <b-form-group class="text-right"   :label="$t('Subject')" label-for="input-1" :class="{ 'form-group--error': $v.form.subject.$error }">
        <b-form-input  v-model.trim="form.subject" @input="setTouched('subject')" type="text"  ></b-form-input>
         <b-form-invalid-feedback  v-if="$v.form.subject.$error"  :state="$v.form.phone.numeric"> {{$t('required_form')}} </b-form-invalid-feedback>
           <div v-if="errorsBack.phone" >
            <b-form-invalid-feedback v-for="error in errorsBack.subject" :key="error"   :state="false"> {{error}}</b-form-invalid-feedback>
         </div>
      </b-form-group>
    
       <!-- input -->
      <b-form-group class="text-right"  :label="$t('Message')" label-for="input-1" :class="{ 'form-group--error': $v.form.message.$error }">
        <b-form-textarea   v-model.trim="form.message" @input="setTouched('Message')" type="text" required rows="3"
      max-rows="6" ></b-form-textarea>
         <b-form-invalid-feedback v-if="$v.form.message.$error" :state="$v.form.message.required"> {{$t('required_form')}} </b-form-invalid-feedback>
      </b-form-group>
        <!-- input -->
      
   </b-col>


   </b-row>

<b-row align-h="center">
    <b-col cols="12" lg="6" xl="6" >
    <b-button type="submit" variant="primary"><b-spinner v-if="isSendingForm" small></b-spinner>  {{$t('Submit')}}</b-button>

     </b-col>
</b-row>






    </b-form>

</b-container>

    </div>
</template>

<script>
import axios from 'axios'
import DoneAlert from '@/components/alerts/DoneAlert.vue'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
//import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
import { required, email,numeric } from 'vuelidate/lib/validators'
 import Vue from "vue";
 import { VueReCaptcha } from "vue-recaptcha-v3";
  Vue.use(VueReCaptcha, { siteKey: "6LeSgcYbAAAAAB4UCywzZ3b2Q1J5QdkXNZWmsROU" });
  export default {
      name:'Competitions',
    data() {
      return {
          form: {
            name: null,
            email: null,
            phone:null,
            subject:null,
            message:null,
            status:null,
            lang_id:this.$i18n.locale,
            recaptcha_response: null,
           

        },
          meta:{
           title:"",
           description:"",
           favicon :""
        },
        showContent: false,
        path:this.$store.state.backendUrl+"api/ar/contact",
        post:[],
          errorsBack:{},
        errorMgs:'',
        ShowError: false,
        show: true,
        ShowDoneAlert:false,
        isSendingForm:false,
        showForm:false,
      }
    },
    components:{
      DoneAlert,ErrorAlert
    }
    ,
      validations: {
      form:{
        phone:{numeric},
        subject : {  required },
        name: {  required },
        message: { required },
        email: { required,email},
    
    }
  },
  /***************** */
    methods: {
       async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()
 
      // Execute reCAPTCHA with action "login".
      this.recaptcha_response = await this.$recaptcha('contactus')
 
      // Do stuff with the received token.
    },
       setTouched(theModel) {
       if(theModel == 'phone' || theModel == 'all' ){this.$v.form.phone.$touch()}
       if(theModel == 'status' || theModel == 'all' ){this.$v.form.status.$touch()}
       if(theModel == 'name' || theModel == 'all' ){this.$v.form.name.$touch()}
       if(theModel == 'message' || theModel == 'all' ){this.$v.form.message.$touch()}
       if(theModel == 'email' || theModel == 'all' ){this.$v.form.email.$touch()}
      
    },
  
           getCreateData(){
           this.showContent =false;
           axios.get(this.path, {params:this.form,
            headers: {
          // 'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                this.post = response.data.success.post;
               this.showContent =true;
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } ,
      onSubmit(event) {
        event.preventDefault()
        this.recaptcha();
        if (!this.$v.$invalid) {

        this.isSendingForm = true;
        //alert(JSON.stringify(this.form))
        const formData = new FormData();
        for (const [key, value] of Object.entries(this.form)) {
             if(value != null){
               formData.append(key,value)
               console.log(key, value);
              }
        }
         formData.append( 'recaptcha_response',this.recaptcha_response);

        axios.post(this.$store.state.backendUrl+'api/ar/contact_message',formData, {
            headers: {
         //  'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
               
                  //console.log(response.data.success);
                  this.isSendingForm = false;
                  this.ShowError=false;
                  this.errorMgs = "";
                  this.ShowDoneAlert = true;
                  setTimeout(()=>{     
                  this.ShowDoneAlert = false;
                  }, 3000);
                  //this.getCreateData();
                  this. ResetForm();
            })
            .catch( (error) => {
                console.log(error.response);
                this.ShowError=true;
                this.errorMgs = error;
                this.isSendingForm = false;
                 if(error){
                    this.errorsBack = error.response.data.errors;
                     console.log(error.response.data.errors);
                  }
            });
           }
      },
     
      ResetForm() {
        
        // Reset our form values
       
        this.form.name = null,
        this.form.subject = null,
         this.form.email = null,
        this.form.phone = null,
        this.form.message = null,
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },
 
    },
     metaInfo() {
      return {
      title: this.meta.title ,
      meta:[{ name: 'description', content: this.meta.description }] ,
      link:[{ rel: 'icon', href: this.meta.favicon }] 
  
    }
     },
    mounted(){
     this.getCreateData();
     this.recaptcha();
      this.meta.title = this.$t('Contact') +' | '+this.$store.state.info.name;
     this.meta.description=this.$store.state.info.short_decription;
     this.meta.favicon =this.$store.state.backendUrl+'/'+this.$store.state.info.favicon ;
    }
  }
</script>